@import '../../variables';

.cst-page-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .cst-login-error {
    color: #f00;
  }

  h1 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 32px;
  }

  form {
    background: #fff;
    margin: 0 auto;
    width: 600px;
    border: 2px solid #474786;
    border-radius: 6px;
    padding: 62px 48px;

    & > * {
      width: calc(100% - 64px);
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;
      margin-bottom: 38px;
      color: #272727;
    }

    button {
      width: 100%;
      padding: 16px 32px;
      border-radius: 6px;
      text-align: left;

      &.primary {
        background: $color-brand-dark-blue;
        color: #fff;
      }
    }
  }
}
